<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[]"
          :currentView="{ label: 'Personas y puestos', icon: 'user-circle' }"
        />
        <h2>Empleados</h2>
      </div>
      <div class="content__buttons">
        <div class="search-bar">
          <input id="search-btn" type="checkbox" />
          <label for="search-btn">Show search bar</label>
          <input id="search-bar" type="text" placeholder="Search..." v-model="searchInput" />
        </div>
        <Menu direction="left">
          <template #label>
            <Button type="button" variant="secondary" size="xsmall">
              <unicon
                class="ellipsis"
                name="ellipsis-h"
                fill="currentColor"
                height="16px"
                width="16px"
              />
            </Button>
          </template>
          <template #options>
            <menu-item @click="showFilters = !showFilters">
              <unicon
                width="16px"
                height="16px"
                name="filter"
                fill="var(--font-color-700)"
              ></unicon>
              Filtrar
            </menu-item>
            <menu-item @click="showFilters = !showFilters">
              <unicon
                width="16px"
                height="16px"
                name="arrows-v"
                fill="var(--font-color-700)"
              ></unicon>
              Ordenar
            </menu-item>
            <span class="menu__divider"></span>
            <menu-item @click="openExportModal">
              <unicon name="file-upload" fill="currentColor" height="16px" width="16px"></unicon>
              Exportar
            </menu-item>
            <menu-item @click="openBatchEmployeesModal">
              <unicon width="16px" height="16px" name="file-import" fill=""></unicon>
              Importar
            </menu-item>
          </template>
        </Menu>
        <Button
          v-if="selectedEmployees.length > 0"
          type="button"
          variant="danger"
          size="small"
          @click="deleteEmployees"
          :disabled="isDeleteLoading"
        >
          <unicon width="16px" height="16px" name="trash" fill="var(--danger-color-400)"></unicon>
          {{ isDeleteLoading ? 'Eliminando...' : 'Eliminar empleado(s)' }}
        </Button>
        <Sidemenu
          ref="categories__menu"
          :title="isCategoryLoading || 'Categorías'"
          :actionButton="{ label: 'Nueva categoría', action: 'addCategory' }"
          :items="categories"
          :actionButtons="[
            {
              icon: 'eye-slash',
              icon2: 'eye',
              name: 'Ocultar',
              name2: 'Mostrar',
              isToggle: true,
              toggleProperty: 'isHidden',
            },
            { icon: 'pen', name: 'Editar' },
            { icon: 'trash', name: 'Eliminar', isDanger: true },
          ]"
          @Ocultar="toggleHideCategory"
          @Editar="onEditCategory"
          @Eliminar="onDeleteCategory"
          @addCategory="openCategoryModal"
          @editItems="editCategories"
        >
          <template #label>
            <Button type="button" variant="secondary" size="small"> Categorías </Button>
          </template>
        </Sidemenu>
        <Button
          :to="{
            name: 'AddEmployee',
          }"
          type="button"
          variant="secondary"
          size="small"
        >
          <unicon width="16px" height="16px" name="plus" fill="var(--main-color-500)"></unicon>
          Agregar empleado
        </Button>
      </div>
    </div>

    <div v-if="showFilters" class="filter__container">
      <sort-item
        v-model="sortParam"
        :options="[...defaultCategories, ...categories]"
        @change="filteredEmployees.sort(compare)"
      />
      <span class="sort__divider"></span>
      <Filters
        @filter="
          (activeFilters) => {
            filterEmployees(activeFilters);
          }
        "
        :filters="[...defaultCategories, ...categories]"
        :filtersOptions="[...defaultCategoriesOptions, ...options]"
      />
    </div>

    <div class="content">
      <table ref="employeesTable">
        <tbody>
          <tr>
            <th></th>
            <th><checkbox v-model="selectAll" /></th>
            <th class="number">#</th>
            <th
              v-for="defaultCategory in defaultCategories"
              :class="{ sortActive: sortParam.text === defaultCategory.id }"
              :key="defaultCategory.id"
            >
              <Menu type="table" direction="below">
                <template #label>
                  <div class="category__label">
                    <p>{{ defaultCategory.name }}</p>
                    <unicon
                      :name="
                        sortParam.text === defaultCategory.id
                          ? sortParam.order === 'up'
                            ? 'sort-amount-up'
                            : 'sort-amount-down'
                          : 'sort'
                      "
                      fill="currentColor"
                      height="14px"
                      width="14px"
                    />
                  </div>
                </template>
                <template #options>
                  <menu-item @click="changeSortParam(defaultCategory.id, 'up')">
                    <unicon
                      width="16px"
                      height="16px"
                      name="sort-amount-up"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ordenar creciente
                  </menu-item>
                  <menu-item @click="changeSortParam(defaultCategory.id, 'down')">
                    <unicon
                      width="16px"
                      height="16px"
                      name="sort-amount-down"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ordenar decreciente
                  </menu-item>
                  <span class="menu__divider"></span>
                  <menu-item @click="openBatchEditEmployeesModal(defaultCategory)">
                    <unicon
                      width="16px"
                      height="16px"
                      name="file-edit-alt"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Importar actualización
                  </menu-item>
                </template>
              </Menu>
            </th>

            <th
              :class="{ sortActive: sortParam.text === category.id }"
              v-for="(category, index) in categories.filter((category) => !category.isHidden)"
              :key="category.id"
            >
              <Menu
                :direction="
                  index === categories.filter((category) => !category.isHidden).length - 1
                    ? 'left'
                    : 'below'
                "
                type="table"
              >
                <template #label>
                  <div class="category__label">
                    <p>{{ category.name }}</p>
                    <unicon
                      :name="
                        sortParam.text === category.id
                          ? sortParam.order === 'up'
                            ? 'sort-amount-up'
                            : 'sort-amount-down'
                          : 'sort'
                      "
                      fill="currentColor"
                      height="13px"
                      width="13px"
                    />
                  </div>
                </template>
                <template #options>
                  <menu-item @click="changeSortParam(category.id, 'up')">
                    <unicon
                      width="16px"
                      height="16px"
                      name="sort-amount-up"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ordenar creciente
                  </menu-item>
                  <menu-item @click="changeSortParam(category.id, 'down')">
                    <unicon
                      width="16px"
                      height="16px"
                      name="sort-amount-down"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ordenar decreciente
                  </menu-item>
                  <span class="menu__divider"></span>
                  <menu-item @click="openBatchEditEmployeesModal(category)">
                    <unicon
                      width="16px"
                      height="16px"
                      name="file-edit-alt"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Importar actualización
                  </menu-item>
                  <span class="menu__divider"></span>
                  <menu-item @click="toggleHideCategory(category)">
                    <unicon
                      width="16px"
                      height="16px"
                      name="eye-slash"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ocultar
                  </menu-item>
                  <menu-item @click="onEditCategory(category)">
                    <unicon
                      width="16px"
                      height="16px"
                      name="pen"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Editar categoría
                  </menu-item>
                  <menu-item :isDanger="true" @click="onDeleteCategory(category)">
                    <unicon
                      width="16px"
                      height="16px"
                      name="trash"
                      fill="var(--danger-color-400)"
                    ></unicon>
                    Eliminar
                  </menu-item>
                </template>
              </Menu>
            </th>
          </tr>
          <template v-if="filteredEmployees.length && !isLoading">
            <tr
              v-for="(employee, index) in filteredEmployees.slice(
                (currentPage - 1) * 100,
                currentPage * 100,
              )"
              :key="employee.id"
              :class="{
                terminated: getState(employee.terminationDate) === 'Cesado',
                selected: selectedEmployees.includes(employee.id),
              }"
            >
              <td>
                <Menu
                  :direction="
                    [
                      filteredEmployees.slice((currentPage - 1) * 100, currentPage * 100).length -
                        1,
                      filteredEmployees.slice((currentPage - 1) * 100, currentPage * 100).length -
                        2,
                    ].includes(index) && filteredEmployees.length > 3
                      ? 'above'
                      : ''
                  "
                >
                  <template #label>
                    <unicon
                      class="ellipsis"
                      name="ellipsis-h"
                      fill="currentColor"
                      height="16.5px"
                      width="16.5px"
                    />
                  </template>
                  <template #options>
                    <menu-item @click="viewEmployee(employee)">
                      <unicon
                        width="16px"
                        height="16px"
                        name="eye"
                        fill="var(--font-color-700)"
                      ></unicon>
                      Ver empleado
                    </menu-item>
                    <menu-item
                      :disabled="false"
                      :to="{
                        name: 'EditEmployee',
                        params: { id: employee.id },
                      }"
                    >
                      <unicon
                        width="16px"
                        height="16px"
                        name="pen"
                        fill="var(--font-color-700)"
                      ></unicon>
                      Editar
                    </menu-item>
                    <menu-item
                      :isDanger="true"
                      @click="
                        selectedEmployees = [employee.id];
                        deleteEmployees();
                      "
                    >
                      <unicon
                        width="16px"
                        height="16px"
                        name="trash"
                        fill="var(--danger-color-400)"
                      ></unicon>
                      Eliminar
                    </menu-item>
                  </template>
                </Menu>
              </td>
              <td>
                <checkbox :value="employee.id" v-model="selectedEmployees" />
              </td>
              <td class="number">{{ index + 1 + (currentPage - 1) * 100 }}</td>
              <template v-for="defaultCategory in defaultCategories">
                <td v-if="defaultCategory.id === 'age'" :key="defaultCategory.id">
                  {{ employee.age }}
                  año(s)
                </td>
                <td v-else-if="defaultCategory.id === 'state'" :key="defaultCategory.id">
                  <span
                    class="state__cell"
                    :class="
                      employee.state === 'Cesado'
                        ? 'state__cell--terminated'
                        : 'state__cell--active'
                    "
                    >{{ employee.state }}</span
                  >
                </td>
                <td
                  v-else-if="employee[defaultCategory.id] && employee[defaultCategory.id].seconds"
                  :key="defaultCategory.id"
                >
                  {{ timeStampToDMY(employee[defaultCategory.id]) }}
                </td>
                <td v-else :key="defaultCategory.id">
                  {{ employee[defaultCategory.id] }}
                </td>
              </template>
              <td
                v-for="category in categories.filter((category) => !category.isHidden)"
                :key="category.id"
              >
                {{
                  options.find((option) => option.id === employee[category.id])
                    ? options.find((option) => option.id === employee[category.id]).name
                    : ''
                }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <empty-state
        v-if="!isLoading && !filteredEmployees.length"
        label="empleados"
        @click="$router.push({ name: 'AddEmployee' })"
      />
      <div v-if="isLoading" class="loading"><loading-spinner /></div>
    </div>
    <div class="employees__footer">
      <div>
        <p v-if="history.length">
          Ultima modificación: {{ new Date(history[history.length - 1].createdOn.seconds * 1000) }}
        </p>
      </div>
      <div class="employees__pagination">
        <p v-if="filteredEmployees.length">
          {{ (currentPage - 1) * 100 + 1 }}-{{
            currentPage * 100 > filteredEmployees.length
              ? filteredEmployees.length
              : currentPage * 100
          }}
          / {{ filteredEmployees.length }} empleados
        </p>
        <p v-else>0 / 0 empleados</p>
        <pagination
          :page="currentPage"
          :pageLimit="Math.ceil(filteredEmployees.length / 100)"
          @previous="currentPage -= 1"
          @next="currentPage += 1"
          @goFirstPage="currentPage = 1"
          @goLastPage="currentPage = Math.ceil(filteredEmployees.length / 100)"
        />
      </div>
    </div>

    <category-modal ref="modal__category" />

    <batch-add-employees-modal ref="modal__batchEmployees" @complete="filterEmployees" />
    <batch-edit-employees-modal ref="modal__batchEditEmployees" @complete="filterEmployees" />

    <employee-view-modal ref="modal__employeeView" :selectedEmployee="selectedEmployee" />

    <export-modal
      ref="modal__export"
      @export="downloadExcel"
      :formats="{
        excel: {
          id: 'excel',
          name: 'Excel',
          options: [{ id: 'employees', name: 'Empleados' }],
        },
      }"
    />

    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState, mapMutations } from 'vuex';
import Checkbox from '@/components/custom/Checkbox.vue';
import Button from '@/components/buttons/Button.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Pagination from '@/components/Pagination.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import Sidemenu from '@/components/menu/Sidemenu.vue';
import Filters from '@/components/filters/Filters.vue';
import SortItem from '@/components/SortItem.vue';
import ExportModal from '@/components/ExportModal.vue';
import EmptyState from '@/components/EmptyState.vue';
import BatchEditEmployeesModal from '@/views/employees/modals/BatchEditEmployeesModal.vue';
import {
  timeStampToDMY,
  timeStampToDate,
  timeStampToAge,
  YMDToTimeStamp,
  timeStampToDMYOffset,
} from '@/dateFormats';
import DEFAULT_CATEGORIES from '@/defaultCategories';
import { faCircleChevronDown, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import * as XLSXS from 'xlsx-style';
import CategoryModal from './modals/CategoryModal.vue';
import BatchAddEmployeesModal from './modals/BatchAddEmployeesModal.vue';
import EmployeeViewModal from './modals/EmployeeViewModal.vue';

export default {
  components: {
    Checkbox,
    Button,
    ConfirmDialogue,
    LoadingSpinner,
    Breadcrumbs,
    EmployeeViewModal,
    CategoryModal,
    BatchAddEmployeesModal,
    BatchEditEmployeesModal,
    ExportModal,
    Pagination,
    Menu,
    MenuItem,
    Sidemenu,
    Filters,
    SortItem,
    EmptyState,
  },
  data() {
    return {
      icons: { circleDown: faCircleChevronDown, excel: faFileExcel },
      isLoading: true,
      currentPage: 1,
      selectAll: false,
      selectedEmployees: [],
      selectedEmployee: {},
      sortParam: { text: 'name', order: 'up' },
      selectedCategory: {},
      isDeleteLoading: false,
      isCategoryLoading: '',
      defaultCategories: DEFAULT_CATEGORIES.CATEGORIES,
      defaultCategoriesOptions: DEFAULT_CATEGORIES.OPTIONS,
      timeStampToDMY,
      timeStampToDate,
      timeStampToAge,
      YMDToTimeStamp,
      timeStampToDMYOffset,
      showFilters: false,
      filteredEmployees: [],
      searchInput: '',
    };
  },

  async mounted() {
    try {
      await this.fetchEmployees();
      await this.fetchCategories();
      await this.fetchOptions();
      const d = new Date();
      await this.fetchHistory(d.getFullYear());
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.setAlert({
        state: 'error',
        message: 'Ocurrió un error al cargar los datos, por favor inténtelo nuevamente',
      });
    }
    this.filterEmployees();
    this.filteredEmployees = this.filteredEmployees.sort(this.compare);
  },

  methods: {
    ...mapActions('employees', ['fetchEmployees', 'batchDelete']),
    ...mapActions('categories', ['fetchCategories', 'updateCategoriesBatch', 'deleteCategory']),
    ...mapActions('options', ['fetchOptions', 'deleteOptionsBatch']),
    ...mapActions('history', ['addLog']),
    ...mapActions('history', ['fetchHistory']),
    ...mapMutations(['setAlert']),

    openExportModal() {
      this.$refs.modal__export.open();
    },

    saveBlobAsFile(name, blob) {
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    downloadExcel() {
      const employees = this.filteredEmployees.map((employee) => {
        const mapedEmployee = {};
        mapedEmployee.id = employee.id;
        this.defaultCategories.forEach((category) => {
          if (['birthDate', 'entryDate', 'terminationDate'].includes(category.id)) {
            mapedEmployee[category.name] = this.timeStampToDMY(employee[category.id]);
          } else {
            mapedEmployee[category.name] = employee[category.id];
          }
        });
        this.categories.forEach((category) => {
          const option = this.options.find(({ id }) => id === employee[category.id]);
          mapedEmployee[category.name] = option ? option.name : '';
        });
        return mapedEmployee;
      });
      const worksheet = XLSX.utils.json_to_sheet(employees);
      const workbook = {
        SheetNames: ['empleados'],
        Sheets: { empleados: worksheet },
      };

      workbook.SheetNames.forEach((sheetName) => {
        const ws = workbook.Sheets[sheetName];
        const range = XLSX.utils.decode_range(ws['!ref']);

        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_cell({ r: 0, c: C });
          if (!ws[address]) continue;
          ws[address].s = { fill: { fgColor: { rgb: '1A96FC' } } };
        }
      });

      const workbookout = XLSXS.write(workbook, {
        bookType: 'xlsx',
        type: 'binary',
      });
      this.saveBlobAsFile(
        'empleados.xlsx',
        new Blob([this.s2ab(workbookout)], { type: 'application/octet-stream' }),
      );
    },

    async deleteEmployees() {
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Eliminar empleado(s)',
        message:
          '¿Estas seguro que quieres eliminar a uno o mas empleados? Eliminar a un empleado lo removera permanentemente de tu lista, esta acción es irreversible.',
        isDestructive: true,
        okButtonText: 'Si, eliminar',
      });

      if (!ok) return;

      this.isDeleteLoading = true;

      try {
        const content = this.getHistoryContent();
        await this.batchDelete(this.selectedEmployees);
        this.setAlert({
          state: 'success',
          message: 'Empleado(s) eliminado(s)',
        });
        await this.addHistory(content);
        this.selectedEmployees = [];
      } catch (error) {
        console.log(error);
        let message = 'Ocurrió un error, por favor inténtelo nuevamente';
        if (error.code === 'permission-denied') {
          message = 'Acceso denegado. No tienes acceso a estos datos.';
        }
        this.setAlert({
          state: 'error',
          message,
        });
      } finally {
        this.filterEmployees();
        this.isDeleteLoading = false;
      }
    },

    getHistoryContent() {
      let content = '';
      this.employees
        .filter((employee) => this.selectedEmployees.includes(employee.id))
        .forEach((employee) => {
          content += `Nombre: ${employee.name}\nNúmero de documento: ${employee.documentNumber}\n\n`;
        });
      return content;
    },

    async addHistory(content) {
      await this.addLog({
        authorId: this.userProfile.id,
        createdOn: new Date(),
        authorName: this.userProfile.name,
        action: 'Eliminar',
        content: `Empleado(s) eliminado(s):\n${content}`,
      });
    },

    viewEmployee(employee) {
      this.selectedEmployee = employee;
      this.$refs.modal__employeeView.open();
    },

    openCategoryModal(category = {}) {
      this.selectedCategory = category;
      this.$refs.modal__category.open(this.selectedCategory);
    },

    openBatchEmployeesModal() {
      this.$refs.modal__batchEmployees.open();
    },

    openBatchEditEmployeesModal(category) {
      this.$refs.modal__batchEditEmployees.open(category);
    },

    async toggleHideCategory(selectedCategory) {
      const category = this.categories.find((cat) => cat.id === selectedCategory.id);
      await this.editCategories([{ ...selectedCategory, isHidden: !category.isHidden }]);
      this.$refs.categories__menu.setCopyItems();
    },

    onEditCategory(selectedCategory) {
      if (!selectedCategory.isEditable) {
        this.setAlert({
          state: 'error',
          message: 'Esta categoría no puede ser editada',
        });
        return;
      }
      this.openCategoryModal(selectedCategory);
      this.$refs.categories__menu.selectedItem = {};
      this.$refs.categories__menu.close();
    },
    async editCategories(categoriesToEdit) {
      await this.updateCategoriesBatch(categoriesToEdit);
    },

    async onDeleteCategory(category = {}) {
      if (!category.isDeletable) {
        this.setAlert({
          state: 'error',
          message: 'Esta categoría no puede ser eliminada',
        });
        return;
      }
      const categoryId = category.id;
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Eliminar categoría',
        message:
          '¿Estas seguro que quieres eliminar esta categoría? Eliminar una categoría la removera permanentemente de tu lista, esta acción es irreversible.',
        isDestructive: true,
        okButtonText: 'Si, eliminar',
      });

      if (!ok) return;

      this.isCategoryLoading = 'Eliminando...';

      try {
        await this.deleteCategory(categoryId);
        await this.deleteOptionsBatch(
          this.options
            .filter((option) => option.categoryId === categoryId)
            .map((option) => option.id),
        );

        this.setAlert({
          state: 'success',
          message: 'Categoría eliminada',
        });
      } catch (error) {
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      } finally {
        this.$refs.categories__menu.selectedItem = {};
        this.isCategoryLoading = '';
      }
    },

    changeSortParam(text, direction) {
      this.sortParam.order = direction;
      this.sortParam.text = text;
      if (this.sortParam.text) {
        this.filteredEmployees = this.filteredEmployees.sort(this.compare);
      }
    },
    compare(a, b) {
      const isOrderUp = this.sortParam.order === 'up';
      let valueA = this.getOptionName(a[this.sortParam.text]) || a[this.sortParam.text] || '';
      let valueB = this.getOptionName(b[this.sortParam.text]) || b[this.sortParam.text] || '';
      if (valueA.seconds) valueA = valueA.seconds;
      if (valueB.seconds) valueB = valueB.seconds;
      if (valueA < valueB) {
        return isOrderUp ? -1 : 1;
      }
      if (valueA > valueB) {
        return isOrderUp ? 1 : -1;
      }
      return 0;
    },

    getOptionName(id) {
      const option = this.options.find((opt) => opt.id === id);
      return option ? option.name : '';
    },

    getCategoryName(id) {
      return this.categories.filter((option) => option.id === id).name;
    },

    getState(timeStamp) {
      if (!timeStamp || !timeStamp.seconds) return 'Activo';
      return new Date() > this.timeStampToDate(timeStamp) ? 'Cesado' : 'Activo';
    },

    filterEmployees(filters = []) {
      this.isLoading = true;
      this.searchInput = '';
      const activeFilters = [];
      let employees = JSON.parse(JSON.stringify(this.employees));
      filters.forEach((filter) => {
        const activeOptions = filter.options
          .filter((option) => option.active)
          .map((option) => option.id);

        if (activeOptions.length) {
          activeFilters.push({
            id: filter.id,
            type: filter.type,
            options: activeOptions,
          });
        }
      });
      if (activeFilters.length) {
        employees = employees.filter((employee) => {
          for (let index = 0; index < activeFilters.length; index += 1) {
            const filter = activeFilters[index];
            let value = '';
            let filterValue = null;
            if (['date', 'month'].includes(filter.type)) {
              filterValue = this.YMDToTimeStamp(filter.options[0]).seconds.toString();
              value = employee[filter.id].seconds.toString();
              console.log(filterValue === value);
              if (filterValue !== value) return false;
            }
            if (filter.type === 'text') {
              filterValue = employee[filter.id].toString();
              value = filter.options;
            } else {
              filterValue = filter.options;
              value = employee[filter.id];
            }
            if (!filterValue.includes(value)) return false;
          }
          return true;
        });
      }
      if (this.sortParam.text) {
        this.filteredEmployees = employees.sort(this.compare);
      } else this.filteredEmployees = employees;
      this.isLoading = false;
    },
  },

  computed: {
    ...mapState({
      history: (state) => state.history.history,
      userProfile: (state) => state.userProfile,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
      employees(state) {
        return state.employees.employees.sort(this.compare).map((employee) => ({
          ...employee,
          age: this.timeStampToAge(employee.birthDate) ? this.timeStampToAge(employee.birthDate).toString() : null,
          state: this.getState(employee.terminationDate),
          otherIncomesArray: employee.otherIncomes,
          otherIncomes: employee.otherIncomes.reduce((acc, val) => acc + val, 0),
        }));
      },
    }),
  },

  watch: {
    selectAll() {
      if (this.selectAll) {
        this.selectedEmployees = this.filteredEmployees.map((employee) => employee.id);
      } else this.selectedEmployees = [];
    },
    searchInput() {
      if (this.searchInput === '') this.filteredEmployees = this.employees;
      if (this.searchInput !== '')this.showFilters = false;
      this.filteredEmployees = this.employees.filter((employee) => {
        let valid = false;
        Object.keys(employee)
          .filter(
            (key) =>
              ![
                'birthDate',
                'entryDate',
                'history',
                'otherIncomesArray',
                'terminationDate',
              ].includes(key),
          )
          .forEach((key) => {
            if (employee[key].toString().includes(this.searchInput)) {
              valid = true;
            }
          });
        return valid;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  background-color: white;
  height: 100%;

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}

.content {
  width: 100%;
  overflow: auto;
  margin-top: 1rem;
  background-color: white;
  flex-grow: 1;
}

.employees__footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.5rem 0 0;
  p {
    font-size: 0.81em;
  }
}

.employees__pagination {
  display: flex;
  gap: 1.5rem;
  justify-content: flex-end;
  padding: 1rem 0.5rem 0 0;
  p {
    font-size: 0.81em;
  }
}

table {
  .category__label {
    display: flex;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 2px;
    &:hover {
      background-color: var(--gray-color-200);
    }
  }

  .selected {
    background-color: var(--gray-color-100);
  }

  .terminated {
    background-color: var(--danger-color-100);
  }

  tr:first-child {
    th {
      &.sortActive,
      &.sortActive * {
        color: var(--font-color-900);
      }
    }
  }
}

.ellipsis {
  padding: 0 0.2rem;
}

p {
  max-width: 100%;
  overflow: hidden;
}

.terminated__cell {
  color: var(--danger-color-400);
}
.active__cell {
  color: var(--success-color-500);
}
.state__cell {
  padding: 0.375rem;
  font-size: 0.75rem;
  font-weight: var(--medium);
  color: var(--font-color-500);
  background-color: var(--gray-color-200);
  border-radius: 4px;
  width: 4rem;
  text-align: center;

  &--active {
    color: var(--success-color-600);
    background-color: var(--success-color-100);
  }

  &--terminated {
    color: var(--danger-color-500);
    background-color: var(--danger-color-200);
  }
}

.filter__container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}

.sort__divider {
  width: 1px;
  height: 30px;
  background-color: var(--gray-color-700);
  margin: 0 0.5rem;
}

table {
  th:first-child,
  td:first-child {
    padding: 0.5rem;
  }
  th:nth-child(2),
  td:nth-child(2) {
    padding: 0.75rem;
  }
}

.search-bar {
  display: flex;
  $btn-d: 48.4px;
  $txt-w: 4 * $btn-d;
  $txt-h: 0.65 * $btn-d;
  $txt-c: #ffeacc;
  $mag-d: 0.5 * $txt-h;
  $mag-f: 0.125;
  $mag-w: $mag-f * $mag-d;
  $mag-c: #000;
  $t: 0.35s;

  [id='search-btn'] {
    position: absolute;
    left: -100vw;

    ~ * {
      --i: var(--pos, 1);
      --j: calc(1 - var(--i));
    }

    &:checked ~ * {
      --pos: 0;
    }
  }

  [for='search-btn'] {
    order: 1;
    overflow: hidden;
    position: relative;
    z-index: 2;
    width: $btn-d;
    height: $btn-d;
    text-indent: -100vw;
    transition: $t ease-out;
    cursor: pointer;
    height: 43px;
    border: solid 1px var(--main-color-500);
    border-radius: 5px;
    border-top-left-radius: calc(var(--i) * 5px);
    border-bottom-left-radius: calc(var(--i) * 5px);
    background: white url(../../assets/search.svg) no-repeat calc(100% - 1rem) center;
  }

  [id='search-bar'] {
    height: 43px;
    width: $txt-w;
    border-radius: 5px;
    padding-left: 1rem;
    border: solid 1px var(--gray-color-900);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    transform: translate(var(--pos, 1 * $txt-w));
    --cp: inset(-2em var(--pos, 100%) -2em -2em);
    -webkit-clip-path: var(--cp);
    clip-path: var(--cp);
    transition: $t;

    &:focus {
      border-color: var(--main-color-500);
      box-shadow: var(--main-color-200) 0px 0px 5px;
    }
  }
}
</style>
